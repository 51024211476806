import {
  RiBookOpenLine,
  RiCloseLine,
  RiExternalLinkLine,
} from "@remixicon/react";
import { Card } from "@tremor/react";
import { useEffect, useState } from "react";

export default function Banner() {
  const [isOpen, setIsOpen] = useState(true);

  // just for demo purpose
  useEffect(() => {
    let timeoutId: any;

    if (!isOpen) {
      timeoutId = setTimeout(() => {
        setIsOpen(true);
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return isOpen ? (
    <>
      <Card className="bg-tremor-background-muted dark:bg-dark-tremor-background-muted">
        <div className="absolute right-0 top-0 pr-3 pt-3">
          <button
            type="button"
            className="rounded-tremor-small p-2 text-tremor-content-subtle hover:text-tremor-content dark:text-dark-tremor-content-subtle hover:dark:text-tremor-content"
            onClick={() => setIsOpen(false)}
            aria-label="Close"
          >
            <RiCloseLine className="h-5 w-5 shrink-0" aria-hidden={true} />
          </button>
        </div>
        <div className="sm:flex sm:items-start sm:space-x-6">
          <div className="inline-flex shrink-0 rounded-tremor-full bg-tremor-brand-muted/50 p-2 dark:bg-dark-tremor-brand-muted/80">
            <span className="flex h-8 w-8 items-center justify-center rounded-tremor-full bg-tremor-brand dark:bg-dark-tremor-brand">
              <RiBookOpenLine
                className="h-5 w-5 text-tremor-brand-inverted dark:text-tremor-brand-inverted"
                aria-hidden={true}
              />
            </span>
          </div>
          <div className="mt-4 sm:mt-0">
            <h3 className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Welcome to your workspace
            </h3>
            <p className="mt-2 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
              Start with our step-by-step guide to configure the workspace to
              your needs. For further resources, our video tutorials and
              audience-specific documentations are designed to provide you with
              a in-depth understanding of our platform.
            </p>
            <div className="mt-6 flex items-center space-x-5">
              <button
                type="button"
                className="whitespace-nowrap rounded-tremor-small bg-tremor-brand px-4 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
              >
                Get started
              </button>
              <a
                href="#"
                className="inline-flex items-center gap-1.5 text-tremor-default font-medium text-tremor-brand dark:text-dark-tremor-brand"
              >
                View tutorials
                <RiExternalLinkLine className="h-4 w-4" aria-hidden={true} />
              </a>
            </div>
          </div>
        </div>
      </Card>
    </>
  ) : null;
}
