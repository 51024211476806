// 'use client';
import { RiAddFill } from "@remixicon/react";
import { Tab, TabGroup, TabList } from "@tremor/react";
import { useState, useCallback } from "react";
import CreateDealModal from "./components/CreateDealModal";

export default function Deals() {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <CreateDealModal
          onClose={() => onClose()}
          setOpen={() => setIsOpen(true)}
          isOpen={isOpen}
        />
      )}
      <div className="p-7">
        <h3 className="text-tremor-title font-bold text-tremor-content-strong dark:text-dark-tremor-content-strong">
          Overview of Deals
        </h3>
        <p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
          View and analyze current stats about your business with the deals that
          you have now.
        </p>
        <TabGroup className="mt-6">
          <TabList>
            <Tab>Qualified</Tab>
            <Tab>Contact Made</Tab>
            <Tab>Sales Pitch</Tab>
            <Tab>Proposal Made</Tab>
            <Tab>Closed</Tab>
          </TabList>
          {/* Content below only for demo purpose placed outside of <Tab> component. Add <TabPanels>, <TabPanel> to make it functional and to add content for other tabs */}
          <div className="relative">
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
            >
              <li className="h-44 rounded-tremor-default bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle" />
              <li className="h-44 rounded-tremor-default bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle" />
              <li className="hidden h-44 rounded-tremor-default bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle sm:block" />
              <li className="hidden h-44 rounded-tremor-default bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle sm:block" />
              <li className="hidden h-44 rounded-tremor-default bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle sm:block" />
              <li className="hidden h-44 rounded-tremor-default bg-tremor-background-subtle dark:bg-dark-tremor-background-subtle sm:block" />
            </ul>
            {/* Change dark:from-gray-950 in parent below according to your dark mode background */}
            <div className="absolute inset-x-0 bottom-0 flex h-32 flex-col items-center justify-center bg-gradient-to-t from-white to-transparent dark:from-gray-950">
              <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                No deals created yet
              </p>
              <p className="mt-2 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Create your first deal to get started
              </p>
              <button
                type="button"
                className="mt-6 inline-flex items-center gap-1.5 whitespace-nowrap rounded-tremor-small bg-tremor-brand px-3 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-dropdown hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-dropdown dark:hover:bg-dark-tremor-brand-emphasis"
                onClick={() => setIsOpen(true)}
              >
                <RiAddFill
                  className="-ml-1 h-5 w-5 shrink-0"
                  aria-hidden={true}
                />
                Create deal
              </button>
            </div>
          </div>
        </TabGroup>
      </div>
    </>
  );
}
