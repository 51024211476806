// 'use client';
import { RiCloseLine } from "@remixicon/react";
import {
  Card,
  Dialog,
  DialogPanel,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
  TextInput,
} from "@tremor/react";

export default ({ isOpen, setOpen, onClose }: any) => {
  return (
    <>
      <Dialog open={isOpen} onClose={setOpen} static={true} className="z-[100]">
        <DialogPanel className="sm:max-w-md">
          <div className="absolute right-0 top-0 pr-3 pt-3">
            <button
              type="button"
              className="rounded-tremor-small p-2 text-tremor-content-subtle hover:bg-tremor-background-subtle hover:text-tremor-content dark:text-dark-tremor-content-subtle hover:dark:bg-dark-tremor-background-subtle hover:dark:text-tremor-content"
              onClick={onClose}
              aria-label="Close"
            >
              <RiCloseLine className="h-5 w-5 shrink-0" aria-hidden={true} />
            </button>
          </div>
          <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Add deal
          </h3>
          <form action="#" method="post">
            <div className="mt-4 space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Contact person
                  <span className="text-red-500">*</span>
                </label>
                <TextInput
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Insert name..."
                  className="mt-2"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Organization
                  <span className="text-red-500">*</span>
                </label>
                <TextInput
                  type="text"
                  id="organisation"
                  name="organisation"
                  placeholder="Insert organisation..."
                  className="mt-2"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Title
                  <span className="text-red-500">*</span>
                </label>
                <TextInput
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Insert title..."
                  className="mt-2"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="type"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Pipeline
                </label>
                <Select
                  id="type"
                  name="type"
                  placeholder="Select issue..."
                  className="mt-2"
                >
                  <SelectItem value="1">Web Design Pipeline</SelectItem>
                </Select>
              </div>
              <div>
                <label
                  htmlFor="owner"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Labels
                </label>
                <MultiSelect
                  id="owner"
                  name="owner"
                  placeholder="Select owner..."
                  defaultValue={["Max Berner"]}
                  className="mt-2"
                >
                  <MultiSelectItem value="Max Berner">
                    Web Design
                  </MultiSelectItem>
                  <MultiSelectItem value="Emma Sydney">
                    Consulting
                  </MultiSelectItem>
                  <MultiSelectItem value="Emily Stone">
                    Brand Identity
                  </MultiSelectItem>
                </MultiSelect>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Expected close date
                  <span className="text-red-500">*</span>
                </label>
                <TextInput
                  type="text"
                  id="close_date"
                  name="close_date"
                  placeholder="DD/MM/YYYY"
                  className="mt-2"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="owner"
                  className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                >
                  Owner
                </label>
                <Select
                  id="type"
                  name="type"
                  placeholder="Select issue..."
                  className="mt-2"
                >
                  <SelectItem value="Ademola Afolabi">
                    Ademola Afolabi (You)
                  </SelectItem>
                </Select>
              </div>
              <button
                type="submit"
                className="w-full whitespace-nowrap rounded-tremor-default bg-tremor-brand px-4 py-2 text-center text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
              >
                Submit
              </button>
            </div>
          </form>
        </DialogPanel>
      </Dialog>
    </>
  );
};
