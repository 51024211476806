// 'use client';
import { RiExternalLinkLine, RiBarChartFill } from "@remixicon/react";
import {
  Card,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import Banner from "../components/Banner";
import Chart from "../components/stats/Chart";
import Chart2 from "../components/stats/Chart2";
import Chart3 from "../components/stats/Chart3";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  {
    name: "Ratio",
    categories: [
      {
        name: "Successfully processed",
        value: "143",
      },
      {
        name: "Errors",
        value: "--",
      },
    ],
  },
  {
    name: "Projects",
    categories: [
      {
        name: "Online shop",
        value: "--",
      },
      {
        name: "Blog",
        value: "--",
      },
      {
        name: "Test project",
        value: "--",
      },
    ],
  },
];

const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Accounts", href: "#", current: false },
  { name: "Deals", href: "#", current: false },
  { name: "Reports", href: "#", current: false },
  { name: "Relationships", href: "#", current: false },
  { name: "Settings", href: "#", current: false },
];

const Logo = (props: any) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path d="M10.9999 2.04938L11 5.07088C7.6077 5.55612 5 8.47352 5 12C5 15.866 8.13401 19 12 19C13.5723 19 15.0236 18.4816 16.1922 17.6064L18.3289 19.7428C16.605 21.1536 14.4014 22 12 22C6.47715 22 2 17.5228 2 12C2 6.81468 5.94662 2.55115 10.9999 2.04938ZM21.9506 13.0001C21.7509 15.0111 20.9555 16.8468 19.7433 18.3283L17.6064 16.1922C18.2926 15.2759 18.7595 14.1859 18.9291 13L21.9506 13.0001ZM13.0011 2.04948C17.725 2.51902 21.4815 6.27589 21.9506 10.9999L18.9291 10.9998C18.4905 7.93452 16.0661 5.50992 13.001 5.07103L13.0011 2.04948Z" />
  </svg>
);

function Requests() {
  return (
    <>
      <div className="p-6">
        <h3 className="font-medium text-tremor-content-strong ">
          Qualified Leads
        </h3>
        <p className="mt-1 text-tremor-default leading-6 text-tremor-content ">
          A breakdown of all qualified leads that have gone through a funnel in the last 30 days. 
          <a
            href="#"
            className="inline-flex items-center gap-1 text-tremor-default text-tremor-brand"
          >
            Learn more
            <RiExternalLinkLine className="h-4 w-4" aria-hidden={true} />
          </a>
        </p>
      </div>
      <div className="border-t border-tremor-border p-6 ">
        <TabGroup>
          <TabList
            variant="solid"
            className="w-full rounded-tremor-small md:w-60"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className="w-full justify-center py-1 ui-selected:text-tremor-content-strong "
              >
                {tab.name}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.name}>
                <div className="mt-6 flex flex-wrap gap-x-20 gap-y-6">
                  {tab.categories.map((item) => (
                    <div key={item.name}>
                      <div className="flex items-center space-x-2">
                        <span className="h-3 w-3 shrink-0 rounded-sm bg-tremor-content-subtle" />
                        <p className="font-semibold text-tremor-content-strong">
                          {item.value}
                        </p>
                      </div>
                      <p className="text-tremor-default text-tremor-content">
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="mt-6 flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted">
                  <div className="text-center">
                    <RiBarChartFill
                      className="mx-auto h-7 w-7 text-tremor-content-subtle"
                      aria-hidden={true}
                    />
                    <p className="mt-2 font-medium text-tremor-content-strong">
                      No data to show
                    </p>
                    <p className="mt-1 text-tremor-default text-tremor-content ">
                      May take 24h for data to be shown.
                    </p>
                  </div>
                </div>
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
    </>
  );
}

export default function Shell() {
  return (
    <>
      <div className="p-4 sm:p-6 lg:p-8">
        <header>
          <div className="mb-4 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-tremor-title font-semibold text-tremor-content-strong ">
              Overview
            </h3>
            <div className="mt-4 items-center sm:mt-0 sm:flex sm:space-x-2">
              <Select
                className="w-full sm:w-fit [&>button]:rounded-tremor-small"
                enableClear={false}
                defaultValue="1"
              >
                <SelectItem value="1">Today</SelectItem>
                <SelectItem value="2">Last 7 days</SelectItem>
                <SelectItem value="3">Last 4 weeks</SelectItem>
                <SelectItem value="4">Last 12 months</SelectItem>
              </Select>
              <Select
                className="mt-2 w-full sm:mt-0 sm:w-fit [&>button]:rounded-tremor-small"
                enableClear={false}
                defaultValue="1"
              >
                <SelectItem value="1">Global</SelectItem>
                <SelectItem value="2">Europe</SelectItem>
                <SelectItem value="3">Asia</SelectItem>
              </Select>
            </div>
          </div>
          <Banner />
        </header>
        <main>
          <div className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <Card className="rounded-tremor-small p-6">
              <Chart />
            </Card>
            <Card className="rounded-tremor-small p-6">
              <Chart2 />
            </Card>
            <Card className="rounded-tremor-small p-6">
              <Chart3 />
            </Card>
          </div>
          <Card className="mt-4 rounded-tremor-small p-0">
            <Requests />
          </Card>
        </main>
      </div>
    </>
  );
}
