import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Accounts from "./pages/Accounts";
import Deals from "./pages/Deals";
import Report from "./pages/Reporting";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },
  {
    path: "/accounts",
    element: (
      <Layout>
        <Accounts />
      </Layout>
    ),
  },
  {
    path: "/deals",
    element: (
      <Layout>
        <Deals />
      </Layout>
    ),
  },
  {
    path: "/reports",
    element: (
      <Layout>
        <Report />
      </Layout>
    )
  },
  {
    path: "/relationships",
    element: <Layout></Layout>,
  },
]);

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement,
// );

const root = createRoot(document.getElementById("root") as any).render(
  <RouterProvider router={router} />,
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
